.container_details {
  display: grid;
  width: 95%;
  grid-template-rows: 50px 75px;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
  /* grid-gap: 5px 5px; */
  /* justify-items: center; */
  align-items: center;
}

.container_details_extra {
  display: grid;
  width: 95%;
  grid-template-rows: 50px 75px;
  grid-template-columns: 4fr 2fr 2fr 5fr;
  /* grid-gap: 5px 5px; */
  /* justify-items: center; */
  align-items: center;
}

.container_details_pricing {
  display: grid;
  width: 45%;
  grid-template-rows: 50px 75px;
  grid-template-columns: 4fr 4fr;
  /* grid-gap: 5px 5px; */
  /* justify-items: center; */
  align-items: center;
}

.container_details_pricing_capacity {
  display: grid;
  width: 65%;
  grid-template-rows: 50px 75px;
  grid-template-columns: 2fr 2fr 2fr;
  /* grid-gap: 5px 5px; */
  /* justify-items: center; */
  align-items: center;
}

.container_item_venues {
  display: grid;
  width: 20%;
  grid-template-rows: 50px 75px;
  grid-template-columns: 1fr;
  align-items: center;
}

.venue_details {
  display: grid;
  grid-template-columns: 10fr 1fr;
}

.container_details_format {
  display: grid;
  width: fit-content;
  grid-template-rows: 50px 75px;
  grid-template-columns: 1.5fr 1fr 1fr 1fr;
  /* grid-gap: 5px 5px; */
  /* justify-items: center; */
  align-items: center;
}

.container_details_league_filter {
  display: grid;
  width: 45%;
  grid-template-rows: 50px 75px;
  grid-template-columns: 4fr 3.5fr;
  /* grid-gap: 5px 5px; */
  /* justify-items: center; */
  align-items: center;
}

.header_details {
  font-weight: bold;
  text-align: left;
  padding: 5px;
  color: white;
  background-color: var(--primary-blue-400);
}

.line {
  width: 95%;
  margin-left: 0;
  height: 2px;
  background-color: var(--primary-green-500);
  border: none;
}

.header_item {
  font-weight: bold;
  text-align: left;
  padding: 5px;
  color: white;
  background-color: var(--primary-blue-400);
}

.header_item_span2 {
  font-weight: bold;
  text-align: left;
  padding: 5px;
  color: white;
  background-color: var(--primary-blue-400);
  grid-column: 7 / span 2;
}

.form_item_span2 {
  /* display: flex;
    flex-direction: row; */
  grid-column: 7 / span 2;
}

.header_item_color {
  font-weight: bold;
  text-align: left;
  padding: 5px;
  color: var(--primary-blue-400);
  background-color: var(--primary-blue-400);
}

.timepickers {
  display: flex;
  padding: 0;
  margin: 0;
}

.timepickers .datepicker_date {
  width: 100%;
  padding-right: 30px;
  padding-bottom: 5px;
}

.timepickers .datepicker_date input {
  height: 56px;
}

.multi-DatePicker-container {
  width: 100%;
}

.multi-DatePicker-input-old {
  width: 100%;
  height: 56px;
  padding-right: 30px;
  padding-bottom: 5px;
  background: var(--backgroundColor);
  border: 1px solid gray;
  border-radius: 5px;
}
.multi-DatePicker-input {
  font-weight: 400;
  padding-top: 0.625rem /* 8px */;
  padding-bottom: 0.625rem;
  padding-left: 0.75rem /* 12px */;
  padding-right: 0.75rem;
  width: fit-content;
  height: 40px;
  border-radius: 0.75rem;
  background: var(--white);
  border: 1px solid var(--secondary-80);
  &:hover {
    border-color: var(--secondary-50);
  }
  &:focus {
    outline-width: 2px;
    outline-color: var(--primary-80);
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Poppins:400");

html {
  font-size: 100%;
} /*16px*/

:root {
  /* Shadcn */
  --background: 0 0% 100%;
  --foreground: 0 0% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 0 0% 3.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 0 0% 3.9%;

  --primary: 0 0% 9%;
  --primary-foreground: 0 0% 98%;

  --secondary: 0 0% 96.1%;
  --secondary-foreground: 0 0% 9%;

  --muted: 0 0% 96.1%;
  --muted-foreground: 0 0% 45.1%;

  --accent: 0 0% 96.1%;
  --accent-foreground: 0 0% 9%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --border: 0 0% 89.8%;
  --input: 0 0% 89.8%;
  --ring: 0 0% 3.9%;

  --radius: 0.5rem;
  /* colors */
  /* error handlers */
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* primary */
  --primary-green-100: #cdf5f0;
  --primary-green-200: #9bebe1;
  --primary-green-300: #68e0d3;
  --primary-green-400: #36d6c4;
  --primary-green-500: #04ccb5;
  --primary-green-600: #03a391;
  --primary-green-700: #027a6d;
  --primary-green-800: #025248;
  --primary-green-900: #012924;

  --primary-red-100: #fbd1dc;
  --primary-red-200: #f7a4ba;
  --primary-red-300: #f37697;
  --primary-red-400: #ef4975;
  --primary-red-500: #eb1b52;
  --primary-red-600: #bc1642;
  --primary-red-700: #8d1031;
  --primary-red-800: #5e0b21;
  --primary-red-900: #2f0510;

  --primary-blue-100: #d1d4de;
  --primary-blue-200: #a3a9bc;
  --primary-blue-300: #747f9b;
  --primary-blue-400: #465479;
  --primary-blue-500: #182958;
  --primary-blue-600: #132146;
  --primary-blue-700: #0e1935;
  --primary-blue-800: #0a1023;
  --primary-blue-900: #050812;

  /* grey */
  --grey-50: #f0f4f8;
  --grey-100: #d9e2ec;
  --grey-200: #bcccdc;
  --grey-300: #9fb3c8;
  --grey-400: #829ab1;
  --grey-500: #627d98;
  --grey-600: #486581;
  --grey-700: #334e68;
  --grey-800: #243b53;
  --grey-900: #102a43;

  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --error-red: #c33730;

  /* fonts */
  --headingFont: arial, helvetica, sans-serif;
  --bodyFont: "Poppins", sans-serif;

  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.5s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 600px;
  --nav-height: 5rem;
  --drawer-length: 12rem;

  /* box-shadow */
  --shadow-1: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-2: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-3: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --shadow-4: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --shadow-5: 0px 2px 8px rgba(0, 0, 0, 0.26);

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  /* colors */
  --table-border: #7486af;

  /*Staging nav bar */
  --staging-nav: #3772ff;

  /* primary */
  --primary-10: #00643b;
  --primary-20: #00825a;
  --primary-30: #009269;
  --primary-40: #00a37a;
  --primary-50: #00b288;
  --primary-60: #00c09b;
  --primary-70: #00cfad;
  --primary-80: #00e0c6;
  --primary-90: #93ecdd;
  --primary-95: #d5f8f2;
  --primary-opacity-08: rgba(0, 224, 198, 0.08);
  --primary-opacity-12: rgba(0, 224, 198, 0.12);
  --primary-opacity-16: rgba(0, 224, 198, 0.16);

  /* secondary */
  --secondary-10: #182958;
  --secondary-20: #223970;
  --secondary-30: #29437c;
  --secondary-40: #314c88;
  --secondary-50: #385490;
  --secondary-60: #566c9f;
  --secondary-70: #7486af;
  --secondary-80: #9aa8c6;
  --secondary-90: #c1cadd;
  --secondary-95: #e7eaf1;
  --secondary-opacity-08: rgba(24, 41, 88, 0.08);
  --secondary-opacity-12: rgba(24, 41, 88, 0.12);
  --secondary-opacity-16: rgba(24, 41, 88, 0.16);

  /* tertiary */
  --tertiary-10: #840444;
  --tertiary-20: #a80c4a;
  --tertiary-30: #bc124d;
  --tertiary-40: #d21751;
  --tertiary-50: #e21b53;
  --tertiary-60: #e73c6b;
  --tertiary-70: #ed5e85;
  --tertiary-80: #f38ca7;
  --tertiary-90: #f8b9ca;
  --tertiary-95: #fce3ea;

  /* info */
  --info-10: #0d47a1;
  --info-20: #1565c0;
  --info-30: #1976d2;
  --info-40: #1e88e5;
  --info-50: #2196f3;
  --info-60: #42a5f5;
  --info-70: #64b5f6;
  --info-80: #90caf9;
  --info-90: #bbdefb;
  --info-95: #e3f2fd;
  --info-opacity-08: rgba(13, 71, 161, 0.08);
  --info-opacity-12: rgba(13, 71, 161, 0.12);
  --info-opacity-16: rgba(13, 71, 161, 0.16);
  --info-opacity-50: rgba(13, 71, 161, 0.5);
  --info-opacity-96: rgba(13, 71, 161, 0.96);

  /* success */
  --success-10: #1b5e20;
  --success-20: #2e7d32;
  --success-30: #388e3c;
  --success-40: #43a047;
  --success-50: #4caf50;
  --success-60: #66bb6a;
  --success-70: #81c784;
  --success-80: #a5d6a7;
  --success-90: #c8e6c9;
  --success-95: #e8f5e9;

  /* warning */
  --warning-10: #e65100;
  --warning-20: #ef6c00;
  --warning-30: #f57c00;
  --warning-40: #fb8c00;
  --warning-50: #ff9800;
  --warning-60: #ffa726;
  --warning-70: #ffb74d;
  --warning-80: #ffcc80;
  --warning-90: #ffe0b2;
  --warning-95: #fff3e0;

  /* error */
  --error-10: #b71c1c;
  --error-20: #c62828;
  --error-30: #d32f2f;
  --error-40: #e53935;
  --error-50: #f44336;
  --error-60: #ef5350;
  --error-70: #e57373;
  --error-80: #ef9a9a;
  --error-90: #ffcdd2;
  --error-95: #ffebee;
  --error-opacity-08: rgba(211, 47, 47, 0.08);
  --error-opacity-12: rgba(211, 47, 47, 0.12);

  /* text colors */
  --text-dark: #000000;
  --text-light: #555555;
  --text-disabled: #757575;
  --text-white: #ffffff;
  --text-link: #182958;
}

body {
  background: var(--backgroundColor);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
}

p {
  margin-bottom: 1rem;
  max-width: 40em;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  margin-bottom: 1.38rem;
  font-family: var(--headingFont);
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

h2 {
  font-size: 2.441rem;
}

h3 {
  font-size: 1.953rem;
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small,
.text_small {
  font-size: 0.8rem;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    /* colors */

    /*Staging nav bar */
    --staging-nav: #3772ff;

    /* primary */
    --primary-10: #00643b;
    --primary-20: #00825a;
    --primary-30: #009269;
    --primary-40: #00a37a;
    --primary-50: #00b288;
    --primary-60: #00c09b;
    --primary-70: #00cfad;
    --primary-80: #00e0c6;
    --primary-90: #93ecdd;
    --primary-95: #d5f8f2;
    --primary-opacity-08: rgba(0, 224, 198, 0.08);
    --primary-opacity-12: rgba(0, 224, 198, 0.12);
    --primary-opacity-16: rgba(0, 224, 198, 0.16);

    /* secondary */
    --secondary-10: #182958;
    --secondary-20: #223970;
    --secondary-30: #29437c;
    --secondary-40: #314c88;
    --secondary-50: #385490;
    --secondary-60: #566c9f;
    --secondary-70: #7486af;
    --secondary-80: #9aa8c6;
    --secondary-90: #c1cadd;
    --secondary-95: #e7eaf1;
    --secondary-opacity-08: rgba(24, 41, 88, 0.08);
    --secondary-opacity-12: rgba(24, 41, 88, 0.12);
    --secondary-opacity-16: rgba(24, 41, 88, 0.16);

    /* tertiary */
    --tertiary-10: #840444;
    --tertiary-20: #a80c4a;
    --tertiary-30: #bc124d;
    --tertiary-40: #d21751;
    --tertiary-50: #e21b53;
    --tertiary-60: #e73c6b;
    --tertiary-70: #ed5e85;
    --tertiary-80: #f38ca7;
    --tertiary-90: #f8b9ca;
    --tertiary-95: #fce3ea;

    /* info */
    --info-10: #0d47a1;
    --info-20: #1565c0;
    --info-30: #1976d2;
    --info-40: #1e88e5;
    --info-50: #2196f3;
    --info-60: #42a5f5;
    --info-70: #64b5f6;
    --info-80: #90caf9;
    --info-90: #bbdefb;
    --info-95: #e3f2fd;
    --info-opacity-08: rgba(13, 71, 161, 0.08);
    --info-opacity-12: rgba(13, 71, 161, 0.12);
    --info-opacity-16: rgba(13, 71, 161, 0.16);
    --info-opacity-50: rgba(13, 71, 161, 0.5);
    --info-opacity-96: rgba(13, 71, 161, 0.96);

    /* success */
    --success-10: #1b5e20;
    --success-20: #2e7d32;
    --success-30: #388e3c;
    --success-40: #43a047;
    --success-50: #4caf50;
    --success-60: #66bb6a;
    --success-70: #81c784;
    --success-80: #a5d6a7;
    --success-90: #c8e6c9;
    --success-95: #e8f5e9;

    /* warning */
    --warning-10: #e65100;
    --warning-20: #ef6c00;
    --warning-30: #f57c00;
    --warning-40: #fb8c00;
    --warning-50: #ff9800;
    --warning-60: #ffa726;
    --warning-70: #ffb74d;
    --warning-80: #ffcc80;
    --warning-90: #ffe0b2;
    --warning-95: #fff3e0;

    /* error */
    --error-10: #b71c1c;
    --error-20: #c62828;
    --error-30: #d32f2f;
    --error-40: #e53935;
    --error-50: #f44336;
    --error-60: #ef5350;
    --error-70: #e57373;
    --error-80: #ef9a9a;
    --error-90: #ffcdd2;
    --error-95: #ffebee;
    --error-opacity-08: rgba(211, 47, 47, 0.08);
    --error-opacity-12: rgba(211, 47, 47, 0.12);

    /* neutrals */
    --neutral-0: #000000;
    --neutral-10: #262626;
    --neutral-20: #434343;
    --neutral-30: #555555;
    --neutral-40: #7b7b7b;
    --neutral-50: #9d9d9d;
    --neutral-60: #c4c4c4;
    --neutral-70: #d9d9d9;
    --neutral-80: #e9e9e9;
    --neutral-90: #f5f5f5;
    --neutral-100: #ffffff;

    /* surface */
    --surface-bg1: #ffffff;
    --surface-bg2: #f8f9fb;
    --sruface-nav: #182958;

    /* text colors */
    --text-dark: #000000;
    --text-light: #555555;
    --text-disabled: #757575;
    --text-white: #ffffff;
    --text-link: #182958;
  }
}
/* 
body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
} */

/* .react-datepicker__input-container input:focus {
  border: 2px solid var(--primary-blue-600);
} */

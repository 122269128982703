.form__row {
  display: flex;
  margin-bottom: 10px;
}

.form__label {
  width: 250px;
  margin-right: 30px;
}

.form__input {
  width: 250px;
}

.form__select {
  width: 250px;
}

p {
    margin-bottom: 0;
  }
  
  .container_details {
    display: grid;
    width: 95%;
    grid-template-rows: 50px 75px;
    grid-template-columns: 1fr 1fr 1fr ;
    align-items: center;
  }

  .container_game_details {
    display: grid;
    width: 95%;
    grid-template-rows: 50px 75px;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  
  .header_details {
    font-weight: bold;
    text-align: left;
    padding: 5px;
    color: white;
    background-color: var(--primary-blue-400);
  }
  
  .line {
    width: 95%;
    margin-left: 0;
    height: 2px;
    background-color: var(--primary-green-500);
    border: none;
  }
  
  .header_item {
    font-weight: bold;
    text-align: left;
    padding: 5px;
    color: white;
    background-color: var(--primary-blue-400);
  }
  
  .header_item_span2 {
    font-weight: bold;
    text-align: left;
    padding: 5px;
    color: white;
    background-color: var(--primary-blue-400);
    grid-column: 7 / span 2;
  }
  
  .form_item_span2 {
    /* display: flex;
    flex-direction: row; */
    grid-column: 7 / span 2;
  }
  
  .header_item_color {
    font-weight: bold;
    text-align: left;
    padding: 5px;
    color: var(--primary-blue-400);
    background-color: var(--primary-blue-400);
  }

  .timepickers {
    display: flex;
    padding: 0;
    margin: 0;
  }
  
  .timepickers .datepicker_date {
    width: 100%;
    padding-right: 30px;
    padding-bottom: 20px;
  }
  
  .timepickers .datepicker_date input {
    height: 56px;
  }
  
  .multi-DatePicker-container {
    width: 100%;
  }
  
  .multi-DatePicker-input {
    width: 100%;
    height: 56px;
    padding-right: 30px;
    padding-bottom: 5px;
    background: var(--backgroundColor);
    border: 1px solid gray;
    border-radius: 5px;
  }
.shift__table {
  white-space: nowrap;
  /* border-collapse: collapse;
  margin: 0.7rem;
  margin-bottom: 2rem; */
}

.headAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

.para {
  margin-top: 0;
}

/* .shift__table th {
  padding: 0.1rem 0.3rem;
  text-align: center;
}

.shift__table td {
  text-align: center;
}

.shift__table thead {
  background-color: var(--grey-100);
}

.shift__table tbody tr:nth-child(even) {
  background-color: var(--grey-100);
} */

@media screen and (max-width: 600px) {
  .shift__table {
    display: none;
  }
  .shift__container {
    display: none;
  }
  .shiftCard {
    display: flex;
    justify-content: space-between;
  }
  .cardActions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (min-width: 600px) {
  .card__container {
    display: none;
    width: 90vw;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.button__create {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 5%;
}

.contract__options {
  display: flex;
  align-items: center;
  justify-content: left;
}

.contract__option {
  display: flex;
  align-items: center;
}

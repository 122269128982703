
.collapse__title {
    display: flex;
}

.collapse__title h5 {
    margin: 10px 0px;
}

.unavailable {
    background-color:  gray;
    color: white !important;
}

.cancelled {
    background-color: red;
}

.button_container {
    display: grid;
}

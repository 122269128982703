.app {
  display: flex;
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
}

.app__body {
  width: calc(100% - 200px);
  flex-grow: 1;
  margin-top: 64px;
  margin-left: 85px;
  padding-top: 2rem;
}

.app__main {
  padding: 0 1rem;
}

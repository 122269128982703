.shift__table {
  white-space: nowrap;
}

.headAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .shift__table {
    display: none;
  }
  .shift__container {
    display: none;
  }
  .shiftCard {
    display: flex;
    justify-content: space-between;
  }

  .cardActions {
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
  }

  .delete__btn {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 600px) {
  .card__container {
    display: none;
    width: 90vw;
  }
}

/* .shift__table th {
  padding: 0.1rem 0.3rem;
  text-align: center;
}

.shift__table td {
  text-align: center;
}

.shift__table thead {
  background-color: var(--grey-100);
}

.shift__table tbody tr:nth-child(even) {
  background-color: var(--grey-100);
} */

/* .shift__table {
  border-collapse: collapse;
  border-spacing: 0;
  font-family: arial, sans-serif;
  table-layout: fixed;
  width: 100%;
}

thead {
  background: lightgray;
  margin: 0;
  position: sticky;
  top: 0;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
  text-align: left;
}

th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
  text-align: left;
  min-width: 100px;
}

td {
  padding: 6px;
}

.container {
  border: 1px solid lightgray;
  height: 500px;
  max-width: 900px !important;
  overflow: auto;
} */
/* @media only screen and (max-width: 600px) {
  .shift__table {
    visibility: hidden;
  }
} */

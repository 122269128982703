/* .shift__table {
  border-collapse: collapse;
  margin: 0.7rem;
  margin-bottom: 2rem;
}

.shift__table th {
  padding: 0.1rem 0.3rem;
  text-align: center;
}

.shift__table td {
  text-align: center;
}

.shift__table thead {
  background-color: var(--grey-100);
}

.shift__table tbody tr:nth-child(even) {
  background-color: var(--grey-100);
} */

.headAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}

.para {
  margin-top: 0;
}

.storybook-button {
  font-weight: 600;
  border: 0;
  border-radius: 12px;
  cursor: pointer;
  line-height: 1;
}
/* primary button settings */
.storybook-button--primary {
  color: black;
  background-color: var(--primary-80);
}
.storybook-button--primary:hover {
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}
.storybook-button--primary:active {
  background-color: #03cab9;
  box-shadow: none;
}

/* secondary button settings */
.storybook-button--secondary {
  border: 1px solid var(--secondary-80);
  color: black;
  background-color: white;
}
.storybook-button--secondary:hover {
  border: 1px solid var(--secondary-70);
  background-color: var(--secondary-opacity-08);
}
.storybook-button--secondary:active {
  border: 1px solid var(--secondary-50);
  background-color: var(--secondary-opacity-12);
}

/* negative button settings */
.storybook-button--negative {
  border: 1px solid var(--error-30);
  color: var(--error-30);
  background-color: white;
}
.storybook-button--negative:hover {
  border: 1px solid var(--error-20);
  color: var(--error-30);
  background-color: var(--error-opacity-08);
}
.storybook-button--negative:active {
  border: 1px solid var(--error-10);
  color: var(--error-30);
  background-color: var(--error-opacity-12);
}

.storybook-button--medium {
  font-size: 16px;
  padding: 8px 24px;
  line-height: normal;
}

.storybook-button--icon {
  padding: 0;
}

/* disabled button settings */

.storybook-button--disabled {
  color: var(--text-disabled);
  background-color: var(--neutral-90);
  cursor: not-allowed;
  border: 1px solid var(--neutral-70);
}

.form {
  display: flex;
  flex-direction: column;
}
.btn__container {
  margin-top: 20px;
  margin-bottom: 100px;
}

.text__editor {
  padding-bottom: 50px;
}

.btn__container__modal {
  display: flex;
  justify-content: space-around;
}

.form_fieldset {
  border: 1px solid #ccc;
  padding: 30px;
  margin-bottom: 20px;
  width: 50%;
}

.multi-DatePicker-container {
  /* padding-bottom: 20px; */
}

.multi-DatePicker-input {
  font-weight: 400;
  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem;
  margin-top: 0.125rem /* 2px */;
  margin-bottom: 0.125rem /* 2px */;
  padding-left: 0.75rem /* 12px */;
  padding-right: 0.75rem;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background: var(--white);
  border: 1px solid var(--secondary-80);
  &:hover {
    border-color: var(--secondary-50);
  }
  &:focus {
    outline-width: 2px;
    outline-color: var(--primary-80);
  }
}

.color_preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding-bottom: 20px;
}

p {
  margin-bottom: 0;
}

.line {
  width: 95%;
  margin-left: 0;
  height: 2px;
  background-color: var(--primary-green-500);
  border: none;
}

.btn__reset {
  margin-left: 20px !important;
}

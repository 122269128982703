p {
    margin-bottom: 0;
}

.filters {
    font-size: 21px;
    display: flex;
    align-items: center;
}

.btn__container {
    display: flex;
    gap: 10px;
    height: fit-content
}

.btn__submit {
    margin-bottom: 20px !important;
}

.container_item {
    display: grid;
    width: 95%;
    grid-template-rows: 50px 75px;
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr 0.25fr;
    /* grid-gap: 5px 5px; */
    /* justify-items: center; */
    align-items: center;
}

.options {
    padding: 20px;
    margin-top: 20px;
    background-color: rgba(191, 191, 191, 0.15);
}

.caption {
    font-size: 18px;
    font-weight: 500;
}

.option {
    margin-top: 10px;
}

.button__create {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 5%;
  }

.format__options {
display: flex;
align-items: center;
justify-content: left;
}

.format__option {
display: flex;
align-items: center;
}
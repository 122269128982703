.matchup {
  text-align: center;
  display: flex;
  width: 500px;
}

.matchup p {
  padding-right: 30px;
}

.scheduleTitle {
  padding-top: 30px;
  margin-bottom: 0px;
}

.datepicker_date {
  width: 150px;
  padding: 0;
}

.datepicker_date input {
  height: 56px;
}

.react-datepicker-popper {
  z-index: 100;
}

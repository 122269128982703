.card__container {
  display: flex;
  flex-direction: row;
}

.divisionCard {
  margin-right: 10px;
}

.division__radio__container {
  display: inline-block;
}

.division__radio__input {
  display: none;
}
